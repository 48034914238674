var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"mt-n2 parametros-max-width",attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"mobile-breakpoint":-1,"items-per-page":5,"hide-default-footer":_vm.items.length < 6 ? true : false},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.index)+" ")]}},{key:"item.nome",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nome)+" ")]}},{key:"item.campo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.campo)+" ")]}},{key:"item.criterio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("nomalizeCondicoes")(item.criterio,item))+" ")]}},{key:"item.obrigatorio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.obrigatorio ? 'Sim' : 'Não')+" ")]}},{key:"item.default",fn:function(ref){
var item = ref.item;
return [(item.default)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"clickable-item",on:{"click":function($event){return _vm.openDialogDefault(item)}}},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.default ? 'Sim' : 'Não')+" ")])]}}],null,true)},[_c('span',[_vm._v("Clique aqui para visualizar os valores default")])]):_c('span',[_vm._v(" "+_vm._s(item.default ? 'Sim' : 'Não')+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"px-1 ml-n1",attrs:{"id":"remove-parametro-editavel","color":"gray","min-width":"0","fab":"","icon":"","x-small":""},on:{"click":function($event){return _vm.openDialogDelete(item.index, item.nome)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-trash-can-outline ")])],1)]}}],null,true)}),_c('dialog-delete',{attrs:{"dialog":_vm.dialogDelete},on:{"update:dialog":function($event){_vm.dialogDelete=$event},"submitTriggered":function($event){_vm.$emit('delete:triggered', _vm.dialogDeleteData.id),
        (_vm.dialogDeleteData = {}),
        (_vm.dialogDelete = false)}}},[_c('template',{slot:"title"},[_vm._v("Deletar Parâmetro #"+_vm._s(_vm.dialogDeleteData.id))]),_c('template',{slot:"body"},[_vm._v("Tem certeza que deseja deletar o parâmetro "),_c('strong',[_vm._v(_vm._s(_vm.dialogDeleteData.nome))]),_vm._v("?")])],2),_c('v-dialog',{attrs:{"scrollable":"","max-width":"500px"},model:{value:(_vm.dialogDefault),callback:function ($$v) {_vm.dialogDefault=$$v},expression:"dialogDefault"}},[_c('v-card',[_c('v-card-title',{staticClass:"py-3",staticStyle:{"text-align":"left !important"}},[_c('h2',[_vm._v("Valores Default")])]),_c('v-divider'),_c('v-card-text',{staticClass:"py-3"},[_vm._v(" "+_vm._s(_vm.dialogDefaultData)+" ")]),_c('v-divider'),_c('v-card-actions',{staticStyle:{"text-align":"right !important","display":"block !important"}},[_c('v-btn',{attrs:{"color":"green darken-1"},on:{"click":function($event){_vm.dialogDefault = false}}},[_vm._v(" OK ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }