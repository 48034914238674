<template>
  <div>
    <v-data-table
      class="mt-n2 parametros-max-width"
      :headers="headers"
      :items="items"
      :search.sync="search"
      :mobile-breakpoint="-1"
      :items-per-page="5"
      :hide-default-footer="items.length < 6 ? true : false"
    >
      <template v-slot:[`item.index`]="{ item }">
        {{ item.index }}
      </template>
      <template v-slot:[`item.nome`]="{ item }">
        {{ item.nome }}
      </template>
      <template v-slot:[`item.campo`]="{ item }">
        {{ item.campo }}
      </template>
      <template v-slot:[`item.criterio`]="{ item }">
        {{ item.criterio | nomalizeCondicoes(item) }}
      </template>
      <template v-slot:[`item.obrigatorio`]="{ item }">
        {{ item.obrigatorio ? 'Sim' : 'Não' }}
      </template>
      <template v-slot:[`item.default`]="{ item }">
        <v-tooltip
          bottom
          v-if="item.default"
        >
          <template v-slot:activator="{ on, attrs }">
            <span
              v-on="on"
              v-bind="attrs"
              class="clickable-item"
              @click="openDialogDefault(item)"
            >
              {{ item.default ? 'Sim' : 'Não' }}
            </span>
          </template>
          <span>Clique aqui para visualizar os valores default</span>
        </v-tooltip>
        <span v-else>
          {{ item.default ? 'Sim' : 'Não' }}
        </span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          id="remove-parametro-editavel"
          color="gray"
          min-width="0"
          class="px-1 ml-n1"
          fab
          icon
          x-small
          @click="openDialogDelete(item.index, item.nome)"
        >
          <v-icon small> mdi-trash-can-outline </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <dialog-delete
      :dialog.sync="dialogDelete"
      @submitTriggered="
        $emit('delete:triggered', dialogDeleteData.id),
          (dialogDeleteData = {}),
          (dialogDelete = false)
      "
    >
      <template slot="title"
        >Deletar Parâmetro #{{ dialogDeleteData.id }}</template
      >
      <template slot="body"
        >Tem certeza que deseja deletar o parâmetro
        <strong>{{ dialogDeleteData.nome }}</strong
        >?</template
      >
    </dialog-delete>
    <v-dialog
      v-model="dialogDefault"
      scrollable
      max-width="500px"
    >
      <v-card>
        <v-card-title
          class="py-3"
          style="text-align: left !important"
        >
          <h2>Valores Default</h2>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-3">
          {{ dialogDefaultData }}
        </v-card-text>
        <v-divider />
        <v-card-actions
          style="text-align: right !important; display: block !important"
        >
          <v-btn
            color="green darken-1"
            @click="dialogDefault = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {
    DialogDelete: () => import('@/components/general/DialogDelete')
  },

  props: {
    items: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    search: '',
    headers: [
      {
        text: 'Nº',
        value: 'index'
      },
      {
        text: 'Nome',
        value: 'nome'
      },
      {
        text: 'Campo',
        value: 'campo'
      },
      {
        text: 'Condição',
        value: 'criterio'
      },
      {
        text: 'Obrigatório',
        value: 'obrigatorio',
        align: 'center'
      },
      {
        text: 'Default',
        value: 'default',
        align: 'center'
      },
      {
        sortable: false,
        text: 'Ações',
        value: 'actions',
        align: 'center'
      }
    ],
    dialogDelete: false,
    dialogDefault: false,
    dialogDeleteData: {},
    dialogDefaultData: ''
  }),

  methods: {
    openDialogDelete(index, nome) {
      this.dialogDeleteData = { id: index, nome: nome };
      this.dialogDelete = true;
    },

    openDialogDefault(item) {
      let valorDefault = item.default;
      this.dialogDefaultData = !this.isCampoMesDados(item.campo)
        ? valorDefault
        : `${valorDefault}-01`;
      this.dialogDefault = true;
    },

    /**
     * Retorna apenas o nome do campo retirando o prefixo da tabela
     */
    retirarPrefixoNome(nomeCampoTabela) {
      let nomeCampo = nomeCampoTabela.split('.')[1];
      return nomeCampo !== undefined ? nomeCampo : nomeCampoTabela;
    },

    isCampoMesDados(nomeCampo) {
      let campo = this.retirarPrefixoNome(nomeCampo.toUpperCase());
      return ['MES_DADOS', 'MES DADOS', 'MÊS DADOS'].includes(campo);
    }
  },

  filters: {
    nomalizeCondicoes(condicao, detalhesParametro) {
      if (detalhesParametro.tipoParametro === 'filtro') {
        return `${condicao} [valores]`;
      } else {
        return `${detalhesParametro.classe} do usuário`;
      }
    }
  }
};
</script>

<style>
/* head -> font-size */
.parametros-max-width th {
  font-size: 13.9px !important;
  font-weight: 400 !important;
  opacity: 9 !important;
}
/* body -> font-size */
.parametros-max-width td {
  font-size: 12.2px !important;
  font-weight: 400 !important;
  opacity: 0.8 !important;
}
/* head > Nº */
.parametros-max-width th:nth-child(1) {
  max-width: 38px !important;
}
/* head > Obrigatorio */
.parametros-max-width th:nth-child(5) {
  max-width: 95px !important;
}
/* head > Default */
.parametros-max-width th:nth-child(6) {
  max-width: 65px !important;
}
/* head > Ações */
.parametros-max-width th:nth-child(7) {
  max-width: 60px !important;
}
</style>
